import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  styled,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Button } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";

interface MainDialogProps {
  onClose: () => void;
  onOutsideClick: () => void;
  dialogTitle?: string;
  dialogDescription?: string;
  children?: React.ReactNode;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  open: boolean;
  entityDocuments: DocumentType[];
  activeDocumentIds: number[];
  setActiveDocumentIds: React.Dispatch<React.SetStateAction<number[]>>;
}

interface DocumentType {
  documentTypeId: number;
  documentTypeName: string;
  documentTypeDescription: string;
  documentGroupId: number;
  documentTypeTableId: number;
}

const sampleData = [
  { id: 2, location: "United Kingdom" },
  { id: 3, location: "Australia" },
  { id: 4, location: "United States" },
  { id: 5, location: "Canada" },
];

const StyledDiv = styled("div")`
  .main-dilog {
    display: flex;
    flex-wrap: wrap;
  }

  .main-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3em;
  }

  .button {
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    outline: none;
    padding: 0.4em 0.6em 0.4em 0.6em;
    border-radius: 4px;
    font-size: 0.8em;

    &.active {
      color: black;
      border: 1px solid green;
      padding: 0.4em 0.6em 0.4em 0.6em;
      border-radius: 4px;
    }
  }
`;

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: "relative",
  padding: "16px 24px 0",

  "& .MuiTypography-root": {
    // marginBottom: theme.spacing(1),
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing("2px"),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const MainDialog: React.FC<MainDialogProps> = ({
  open,
  onClose,
  onOutsideClick,
  dialogTitle,
  dialogDescription,
  children,
  width = "95vw",
  height = "95vh",
  minWidth = "800px",
  minHeight = "450px",
  entityDocuments,
  activeDocumentIds,
  setActiveDocumentIds,
}) => {
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState<
    number | null
  >(null);

  const [filteredDocumentGroup, setFilteredDocumentGruop] = useState<
    DocumentType[]
  >([]);

  const handleChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedDocumentTypeId(selectedValue);
  };

  useEffect(() => {
    if (selectedDocumentTypeId) {
      const filterType = entityDocuments.filter(
        (doc) => doc.documentGroupId === selectedDocumentTypeId
      );

      setFilteredDocumentGruop(filterType);
    } else {
      setFilteredDocumentGruop([]);
    }
  }, [entityDocuments, selectedDocumentTypeId]);

  const FilterGroupData = filteredDocumentGroup
    .filter((doc) => doc.documentTypeName)
    .map((doc) => ({
      id: doc.documentTypeId,
      name: doc.documentTypeName,
      table: doc.documentTypeTableId,
    }));

  useEffect(() => {
    if (!open) {
      setSelectedDocumentTypeId(null);
      setFilteredDocumentGruop([]);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onOutsideClick();
        } else {
          onClose();
        }
      }}
      maxWidth={"xl"}
      PaperProps={{
        style: {
          width: width,
          height: height,
          minWidth: minWidth,
          minHeight: minHeight,
          paddingLeft: "10px",
          paddingRight: "10px",

          maxWidth: "80vw !important",
        },
      }}
    >
      <CustomDialogTitle>
        <StyledDiv>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" sx={{ flex: 1, ml: "2em " }}>
                Please select the Location
              </Typography>
              <FormControl
                fullWidth
                style={{
                  maxWidth: "280px",
                  marginRight: "7.5em",
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#e0e0e0",
                      borderWidth: "1px",
                    },
                    "&:hover fieldset": {
                      borderColor: " #bdbdbd ",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#bdbdbd",
                    },
                  },
                }}
              >
                <InputLabel sx={{ padding: "0.1rem 0.2rem" }}>
                  Select Location
                </InputLabel>
                <Select
                  value={selectedDocumentTypeId || ""}
                  label="Select Location "
                  onChange={handleChange}
                >
                  {sampleData.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {FilterGroupData.length > 0 && (
              <div
                className="main-dilog"
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body1" sx={{ flex: 1, ml: 1 }}>
                  Please select the Intake before submit the form
                </Typography>
                <div
                  style={{ display: "flex", flexWrap: "nowrap", gap: "10px" }}
                >
                  {FilterGroupData.map((doc, index) => (
                    <div className="main-title" key={index}>
                      <div
                        className={`button ${
                          activeDocumentIds.includes(doc.id) ? "active" : ""
                        }`}
                        onClick={() => {
                          setActiveDocumentIds((prevIds) => {
                            if (prevIds.includes(doc.id)) {
                              return prevIds.filter((id) => id !== doc.id);
                            } else {
                              return [...prevIds, doc.id];
                            }
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          border: activeDocumentIds.includes(doc.id)
                            ? "1px solid green"
                            : "1px solid #ccc",
                          borderRadius: "4px",
                          transition: "background-color 0.3s, color 0.3s",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong>{doc.name}</strong>
                        {activeDocumentIds.includes(doc.id) && (
                          <CheckIcon
                            style={{
                              fontSize: "14px",
                              marginLeft: "8px",
                              color: "white",
                              backgroundColor: "green",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}{" "}
                </div>
              </div>
            )}
          </Box>
        </StyledDiv>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          {dialogDescription}
        </Typography>
        <CloseButton onClick={onClose}>
          <i className="las la-times"></i>
        </CloseButton>
      </CustomDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default MainDialog;
